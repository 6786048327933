import { useSelector } from 'react-redux';
import { selectLogin } from './pages/AuthPage/slice/selectors';
import { Navigate, Outlet } from 'react-router-dom';
import PermissionDenied from './PermissionDenied';

export default function RoleAccess({
  allowedRoles,
}: {
  allowedRoles: string[];
}) {
  const { user } = useSelector(selectLogin);

  const isUserRoleAllowAccess = allowedRoles.find(role =>
    user.permissions.toLowerCase().includes(role.toLowerCase()),
  );

  return isUserRoleAllowAccess ? <Outlet /> : <PermissionDenied />;
}
