import { Flex } from 'antd';

export default function PermissionDenied() {
  return (
    <Flex vertical gap={8} style={{ padding: 16 }}>
      <h3>Access Denied</h3>

      <p>
        You do not have the necessary permissions to view this page. If you
        believe this is an error, please contact your administrator for further
        assistance.
      </p>
    </Flex>
  );
}
