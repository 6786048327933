import React from 'react';
import { Flex, Space, Typography } from 'antd';
import { AppBreadcrumb } from './AppBreadcrumb';
import { MyIconButton } from '../Button';
import { ArrowLeftOutlined } from '@ant-design/icons';

export function AppPageHeader({ title, subTitle, extra }: any) {
  return (
    <div style={{ padding: '16px 16px 0' }}>
      <AppBreadcrumb />

      <div style={{ margin: '16px 0' }}>
        <Flex justify="space-between">
          <Flex gap={4}>
            <MyIconButton
              icon={<ArrowLeftOutlined />}
              onClick={() => window.history.back()}
            />

            <Flex vertical>
              <Typography.Title level={5} style={{ margin: 0 }}>
                {title}
              </Typography.Title>
              {subTitle && (
                <Typography.Paragraph>{subTitle}</Typography.Paragraph>
              )}
            </Flex>
          </Flex>

          {extra && (
            <Space size="middle">
              {extra.map((e, idx) => (
                <div key={idx}>{e}</div>
              ))}
            </Space>
          )}
        </Flex>
      </div>
    </div>
  );
}
