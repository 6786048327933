import DashboardPage from './pages/DashboardPage';
import { CategoryPage } from './pages/CategoryPage/Loadable';
import {
  InternalUserCreate,
  InternalUserEdit,
  InternalUserPage,
} from './pages/InternalUserPage/Loadable';

import AppRoute from 'utils/AppRoute';
import {
  DocumentCreate,
  DocumentEdit,
  DocumentPage,
} from './pages/DocumentPage/Loadable';
import { DocTypePage } from './pages/DocTypePage/Loadable';
import { InstitutionPage } from './pages/InstitutionPage/Loadable';
import { UserPage } from './pages/UserPage/Loadable';
import { Route } from 'react-router-dom';
import RoleAccess from './RoleAccess';
import { ROLE } from 'types/Roles';

const allowAll = [ROLE.SuperAdmin, ROLE.Admin, ROLE.Editor, ROLE.Viewer];
const allowEditor = [ROLE.SuperAdmin, ROLE.Admin, ROLE.Editor];

const routes = [
  {
    id: '1',
    path: AppRoute.dashboard,
    title: 'Dashboard',
    guard: <RoleAccess allowedRoles={allowAll} />,
    component: <DashboardPage />,
  },
  {
    id: '2',
    path: AppRoute.category,
    title: 'Category',
    guard: <RoleAccess allowedRoles={allowEditor} />,
    component: <CategoryPage />,
  },
  {
    id: '3',
    path: AppRoute.docType,
    title: 'ប្រភេទឯកសារ',
    guard: <RoleAccess allowedRoles={allowEditor} />,
    component: <DocTypePage />,
  },
  {
    id: '4',
    path: AppRoute.institution,
    title: 'អង្គភាព/ស្ថាប័ន',
    guard: <RoleAccess allowedRoles={allowEditor} />,
    component: <InstitutionPage />,
  },
  {
    id: '5',
    path: AppRoute.document,
    title: 'ឯកសារ',
    exact: true,
    guard: <RoleAccess allowedRoles={allowAll} />,
    component: <DocumentPage />,
  },
  {
    id: '6',
    path: `${AppRoute.document}/create`,
    title: 'បង្កើតឯកសារថ្មី',
    guard: <RoleAccess allowedRoles={allowEditor} />,
    component: <DocumentCreate />,
  },
  {
    id: '7',
    path: `${AppRoute.document}/edit/:id`,
    title: 'កែប្រែឯកសារ',
    guard: <RoleAccess allowedRoles={allowEditor} />,
    component: <DocumentEdit />,
  },
  {
    id: '8',
    path: AppRoute.user,
    title: 'មន្ត្រីអង្គភាព',
    exact: true,
    guard: <RoleAccess allowedRoles={[ROLE.SuperAdmin, ROLE.Admin]} />,
    component: <UserPage />,
  },
  {
    id: '9',
    path: AppRoute.internalUser,
    title: 'អ្នកប្រើប្រាស់ប្រព័ន្ធ',
    exact: true,
    guard: <RoleAccess allowedRoles={[ROLE.SuperAdmin]} />,
    component: <InternalUserPage />,
  },
  {
    id: '10',
    path: `${AppRoute.internalUser}/create`,
    title: 'បង្កើត អ្នកប្រើប្រាស់ប្រព័ន្ធ',
    guard: <RoleAccess allowedRoles={[ROLE.SuperAdmin]} />,
    component: <InternalUserCreate />,
  },
  {
    id: '11',
    path: `${AppRoute.internalUser}/edit/:id`,
    title: 'កែប្រែ អ្នកប្រើប្រាស់ប្រព័ន្ធ',
    guard: <RoleAccess allowedRoles={[ROLE.SuperAdmin]} />,
    component: <InternalUserEdit />,
  },
];

export default routes;
