import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import 'sanitize.css/sanitize.css';
import { App } from 'app';
import { HelmetProvider } from 'react-helmet-async';
import { configureAppStore } from 'store/configureStore';
import './locales/i18n';
import './styles.css';
import { ConfigProvider } from 'antd';

const root = document.getElementById('root');
if (!root) throw new Error('No root element found');

const store = configureAppStore();

createRoot(root).render(
  <Provider store={store}>
    <ConfigProvider
      theme={{
        token: {
          fontFamily: 'Battambang, Open Sans',
        },
      }}
    >
      <HelmetProvider>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </HelmetProvider>
    </ConfigProvider>
  </Provider>,
);

// Hot reloadable translation json files
// if (module.hot) {
//   module.hot.accept(['./locales/i18n'], () => {
//     // No need to render the App again because i18next works with the hooks
//   });
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
