import React, { Component } from 'react';
import {
  LogoutOutlined,
  SettingOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Menu, Dropdown, Avatar, Space } from 'antd';
import { AppStorage } from 'utils';
import './styles.css';

export class HeaderAvatar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      displayName: '',
    };
  }

  componentDidMount() {
    const userData = AppStorage.getAuthData();
    let displayName = 'Anonymous';

    if (userData && userData.email) {
      displayName = userData.email.split('@')[0];
    }

    this.setState({ user: userData, displayName });
  }

  handleLogout = () => {
    AppStorage.removeAuthData(true);
  };

  render() {
    return (
      <div className="avatar-wrapper">
        <Dropdown
          menu={{
            items: [
              {
                key: 'user-profile',
                disabled: true,
                label: (
                  <Space>
                    <UserOutlined />
                    <span>User Profile</span>
                  </Space>
                ),
              },
              {
                type: 'divider',
              },
              {
                key: 'logout',
                label: (
                  <Space>
                    <LogoutOutlined />
                    <span>Logout</span>
                  </Space>
                ),
                onClick: this.handleLogout,
              },
            ],
          }}
        >
          <div>
            <Avatar
              style={{ backgroundColor: '#87d068', marginRight: '10px' }}
              icon={<UserOutlined />}
            />
          </div>
        </Dropdown>
      </div>
    );
  }
}
