import IClientHttp from 'lib/http/IClientHttp';
import { ApiRoute } from 'utils';
import BaseService from './BaseService';
import { ITEM_LIMIT } from 'utils/constants';
import { generateReportFilename, generateUniqueID } from 'utils/string';

class DocumentService extends BaseService {
  constructor(http: IClientHttp) {
    super(ApiRoute.document(), http);
  }

  async export() {
    try {
      const result = await this.http.get(`${this.route}/export`, {
        params: {
          sb: '-docIn.date',
          limit: 1000, // FIXME: Temporary
        },
        responseType: 'blob',
      });

      const blob = new Blob([result], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      // Create a link element
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = generateReportFilename();
      document.body.appendChild(a);
      a.click(); // Trigger the download

      // Clean up
      a.remove();
      window.URL.revokeObjectURL(url);
    } catch (err) {
      throw err;
    }
  }

  async getAll(params?: any) {
    try {
      const result = await this.http.get(this.route, {
        params: {
          limit: ITEM_LIMIT,
          sb: '-createdAt',
          ...params,
        },
      });

      return result.map(doc => {
        return {
          ...doc,
          location:
            doc.location &&
            doc.location.length > 0 &&
            doc.location.map(loc => {
              const locId = generateUniqueID();
              return { ...loc, id: locId };
            }),
          displayLocation:
            doc.location &&
            doc.location.length > 0 &&
            doc.location.reduce((acc, loc) => {
              const locId = generateUniqueID();

              if (!acc[loc.district]) {
                acc[loc.district] = {
                  id: locId,
                  district: loc.district,
                  communes: [],
                };
              }

              acc[loc.district].communes.push(loc.commune);

              return acc;
            }, {}),
        };
      });
    } catch (err) {
      throw err;
    }
  }
}

export default DocumentService;
