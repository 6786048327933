import { useEffect } from 'react';
import { translations } from 'locales/translations';
import { Helmet } from 'react-helmet-async';
import { AppStorage } from 'utils';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import AppRoute from 'utils/AppRoute';

export function ResetPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    AppStorage.removeAuthData(false);
    navigate(AppRoute.login, {
      replace: true,
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>លិខិតចេញ-ចូល</title>
        <meta name="description" content={t(translations.appName)} />
      </Helmet>

      <p>Reseting...</p>
    </>
  );
}
