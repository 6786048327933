import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from './index';

const selectSlice = (state: RootState) => {
  if (state && state.login) {
    return state.login;
  }

  return initialState;
};

export const selectLogin = createSelector([selectSlice], state => state);
