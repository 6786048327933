import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { selectLogin } from './pages/AuthPage/slice/selectors';

const PrivateRoute = ({ children, ...rest }: any) => {
  const { isAuth, user } = useSelector(selectLogin);

  if (!isAuth) {
    return <Navigate to="/login" state={{ from: '' }} />;
  }

  return children;
};

export default PrivateRoute;
