import React from 'react';
import { Breadcrumb } from 'antd';
import { Link, useLocation } from 'react-router-dom';

const breadcrumbNameMap = {
  backoffice: 'ទំព័រដើម',
  dashboard: 'Dashboard',
  document: 'ឯកសារ',
  user: 'មន្ត្រីអង្គភាព',
  create: 'បង្កើត',
  edit: 'កែប្រែ',
  'internal-user': 'អ្នកប្រើប្រាស់ប្រព័ន្ធ',
};

export function AppBreadcrumb() {
  const location = useLocation();
  const pathSnippets = location.pathname.split('/').filter(i => i);

  const extraBreadcrumbItems: any = pathSnippets.reduce((acc, pathname) => {
    if (!breadcrumbNameMap[pathname]) {
      return acc;
    }

    let urlPath = `/${pathname}`;
    return [
      ...acc,
      {
        path: urlPath,
        title: breadcrumbNameMap[pathname],
      },
    ];
  }, []);

  return (
    <Breadcrumb
      itemRender={(currentRoute, params, items, paths) => {
        const isLast = currentRoute?.path === items[items.length - 1]?.path;

        return isLast ? (
          <span>{currentRoute.title}</span>
        ) : (
          <Link to={`/${paths.join('/')}`}>{currentRoute.title}</Link>
        );
      }}
      items={[...extraBreadcrumbItems]}
    />
  );
}
