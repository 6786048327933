import React from 'react';
import { Flex, Layout, Space, Typography } from 'antd';
import SideBarMenuData from 'app/SideBarMenuData';
import { Logo } from 'app/components/UI/Icon';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';

const { Sider } = Layout;

export function AppSidebar({ theme }) {
  const { t } = useTranslation();

  const getFullYear = React.useCallback(() => new Date().getFullYear(), []);

  return (
    <Sider
      theme={theme}
      width={220}
      style={{
        height: '100vh',
        position: 'fixed',
        left: 0,
        top: 0,
        paddingTop: '16px',
      }}
    >
      <Flex vertical align="center" gap="small">
        <Logo width="48px" />
        {/* <Typography.Text style={{ fontSize: '12px', color: '#333' }}>
          ប្រព័ន្ធគ្រប់គ្រងឯកសារ
        </Typography.Text> */}
      </Flex>

      <SideBarMenuData theme={theme} />

      <Typography.Text
        style={{
          fontSize: '12px',
          position: 'absolute',
          bottom: '16px',
          textAlign: 'center',
          width: '100%',
        }}
      >
        {t(translations.appName)} © {getFullYear()}
      </Typography.Text>
    </Sider>
  );
}
