import React from 'react';
import { Button } from 'antd';

interface MyIconButtonProps {
  children?: any;
  icon: any;
  onClick: any;
}

export function MyIconButton({ children, icon, onClick }: MyIconButtonProps) {
  return (
    <Button type="text" size="small" icon={icon} onClick={onClick}>
      {children}
    </Button>
  );
}
