import React from 'react';
import { Table, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Link } from 'react-router-dom';
import AppRoute from 'utils/AppRoute';

const { Text } = Typography;
const columns: ColumnsType<any> = [
  {
    title: 'ល.រ',
    dataIndex: 'id',
    align: 'center',
    width: '36px',
    render: (e, record, index) => <Text>{index + 1}</Text>,
  },
  {
    title: 'ឈ្មោះ',
    dataIndex: 'id',
    align: 'center',
    render: (e, record) => (
      <Text style={{ textAlign: 'left', display: 'block' }}>
        <Link to={`${AppRoute.document}?fq=user:${record._id}`}>
          {record?.fn || ''} {record?.ln || ''}
        </Link>
      </Text>
    ),
  },
  {
    title: 'កំពុងដំណើរការ',
    dataIndex: 'id',
    align: 'center',
    render: (e, record) => (
      <Text>
        <Link
          to={`${AppRoute.document}?fq=user:${record._id},docStatus:inprogress`}
        >
          {record?.totalInProgress || 0}
        </Link>
      </Text>
    ),
  },
  {
    title: 'របាយការណ៍រួច',
    dataIndex: 'id',
    align: 'center',
    render: (e, record) => (
      <Text>
        <Link
          to={`${AppRoute.document}?fq=user:${record._id},docStatus:finish`}
        >
          {record?.totalFinish || 0}
        </Link>
      </Text>
    ),
  },
  {
    title: 'ករណីសរុបរួម',
    dataIndex: 'id',
    align: 'center',
    render: (e, record) => (
      <Text>
        <Link to={`${AppRoute.document}?fq=user:${record._id}`}>
          {record?.total || 0}
        </Link>
      </Text>
    ),
  },
];

function DocByUserReport({ data }) {
  return (
    <Table
      size="small"
      rowKey="id"
      columns={columns}
      dataSource={data}
      pagination={false}
      bordered
      summary={pageData => {
        let summary = {
          inProgress: 0,
          finish: 0,
          totalDocSign: 0,
          totalDocUnsign: 0,
        };

        pageData.forEach(
          ({ totalInProgress, totalFinish, totalDocSign, totalDocUnsign }) => {
            summary.inProgress += totalInProgress || 0;
            summary.finish += totalFinish || 0;
            summary.totalDocSign += totalDocSign || 0;
            summary.totalDocUnsign += totalDocUnsign || 0;
          },
        );

        return (
          <Table.Summary fixed>
            <Table.Summary.Row>
              <Table.Summary.Cell colSpan={2} align="center" index={1}>
                <Text strong>សរុប</Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell
                index={2}
                align="center"
                className="bg-light-yellow"
              >
                <Text>
                  <Link
                    to={`${AppRoute.document}?fq=docStatus:inprogress,user:all`}
                  >
                    {summary.inProgress}
                  </Link>
                </Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell
                index={3}
                align="center"
                className="bg-light-yellow"
              >
                <Text>
                  <Link
                    to={`${AppRoute.document}?fq=docStatus:finish,user:all`}
                  >
                    {summary.finish}
                  </Link>
                </Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell
                index={4}
                align="center"
                className="bg-light-red"
              >
                <Text>
                  <Link to={`${AppRoute.document}?fq=user:all`}>
                    {summary.inProgress + summary.finish}
                  </Link>
                </Text>
              </Table.Summary.Cell>
            </Table.Summary.Row>
            <Table.Summary.Row>
              <Table.Summary.Cell colSpan={3} align="center" index={1}>
                មេចាររបាយការណ៍រួច
              </Table.Summary.Cell>
              <Table.Summary.Cell
                index={2}
                align="center"
                className="bg-light-blue"
              >
                <Text>
                  <Link to={`${AppRoute.document}?fq=user:all,docStatus:sign`}>
                    {summary.totalDocSign}
                  </Link>
                </Text>
              </Table.Summary.Cell>
            </Table.Summary.Row>
            <Table.Summary.Row>
              <Table.Summary.Cell colSpan={3} align="center" index={1}>
                សល់នៅមេខេត្ត
              </Table.Summary.Cell>
              <Table.Summary.Cell
                index={2}
                align="center"
                className="bg-light-orange"
              >
                <Text>
                  <Link
                    to={`${AppRoute.document}?fq=user:all,docStatus:unsign`}
                  >
                    {summary.totalDocUnsign}
                  </Link>
                </Text>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        );
      }}
    />
  );
}

export default DocByUserReport;
