import IClientHttp from 'lib/http/IClientHttp';
import { ApiRoute, AppStorage } from 'utils';

class AuthService {
  private readonly http: IClientHttp;

  constructor(http: IClientHttp) {
    this.http = http;
  }

  async login({ identity, password }) {
    try {
      const user = await this.http.post(ApiRoute.login, {
        idtt: identity,
        pwd: password,
      });

      AppStorage.setAuthData(user);

      return user;
    } catch (err) {
      throw err;
    }
  }
}

export default AuthService;
