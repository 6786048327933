import React from 'react';
import { Layout } from 'antd';
import { AppHeader, AppSidebar } from 'app/components/UI/Layout';
import { Outlet } from 'react-router-dom';

function MainLayout() {
  return (
    <Layout>
      <Layout>
        <AppSidebar theme="light" />

        <Layout style={{ marginLeft: '220px', backgroundColor: '#fafafa' }}>
          <AppHeader />

          <Outlet />
        </Layout>
      </Layout>
    </Layout>
  );
}

export default MainLayout;
